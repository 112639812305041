import React from "react";
import Image from "gatsby-image";

const Product = ({ body, fluid, alt, regular }: { body: any; fluid: any; alt: string; regular: boolean }) => {
    if (regular) {
        return (
            <div className="flex flex-col sm:flex-row items-start justify-between sm:pb-12 w-full">
                <div className="md:w-2/3 xl:w-3/4 pr-4 xl:pr-12">{body}</div>
                <div className="md:w-1/3 xl:w-1/4 flex flex-col items-end">
                    <Image fluid={fluid} alt={alt} className="w-full right-0 mt-16 lg:mt-0" />
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex flex-col sm:flex-row items-start sm:justify-between sm:pb-12 w-full">
                <div className="block md:hidden md:w-2/3 xl:w-3/4 md:pl-4 xl:pl-12">{body}</div>
                <div className="md:w-1/3 xl:w-1/4 flex flex-col items-start">
                    <Image fluid={fluid} className="w-full left-0 mt-16 lg:mt-0" alt={alt} />
                </div>
                <div className="hidden md:block md:w-2/3 xl:w-3/4 md:pl-4 md:pl-8 xl:pl-12">{body}</div>
            </div>
        );
    }
};

export default Product;
