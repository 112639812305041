import React from "react";
import Image from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { getFluid } from "../util/helper";

interface Partner {
	url: string;
	fluid: any;
	alt: string;
}

const PartnerSection = ({ partner }: { partner: any }) => (
	<a href={partner.url} target="_blank">
		<div className="flex flex-col items-center py-16 text-center">
			<div style={{ width: "200px" }} className="inline-block">
				<Image itemProp="image" fluid={partner.fluid} alt={partner.alt} />
			</div>
		</div>
	</a>
);

const Partners = () => {
	const { t } = useTranslation();
	const data = useStaticQuery(graphql`
		query PartnersQuery {
			allFile(
				filter: {
					relativePath: {
						in: [
							"partner/adyen.png"
							"partner/chubb.png"
							"partner/expedia.png"
							"partner/gta.png"
							"partner/hoppa.png"
							"partner/hotelbeds2.png"
							"partner/jetsmart.png"
							"partner/microsoft.png"
							"partner/salesforce.png"
							"partner/six-payment.png"
							"partner/travelfusion.png"
							"partner/wizzair.png"
						]
					}
				}
			) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`);

    const PARTNERS: Partner[] = [
        {
            url: "https://jetsmart.com/cl/es/",
            alt: "JetSmart",
            fluid: getFluid(data.allFile.edges, "partner/jetsmart.png"),
        },
        {
            url: "http://six-payment-services.com/en/home.html/",
            alt: "Six Payment",
            fluid: getFluid(data.allFile.edges, "partner/six-payment.png"),
        },
        {
            url: "https://www.hotelbeds.com/home",
            alt: "Hotelbeds",
            fluid: getFluid(data.allFile.edges, "partner/hotelbeds2.png"),
        },
        {
            url: "https://wizzair.com/en-gb/information-and-services/about-us/company-information",
            alt: "Wizz Air",
            fluid: getFluid(data.allFile.edges, "partner/wizzair.png"),
        },
        {
            url: "http://www.gta-travel.com/en/about",
            alt: "GTA Travel",
            fluid: getFluid(data.allFile.edges, "partner/gta.png"),
        },
        {
            url: "https://www.expediapartnersolutions.com/",
            alt: "Expedia",
            fluid: getFluid(data.allFile.edges, "partner/expedia.png"),
        },
        {
            url: "https://www.hoppa.com/en/hoppago",
            alt: "Hoppa",
            fluid: getFluid(data.allFile.edges, "partner/hoppa.png"),
        },
        {
            url: "https://www.chubb.com/us-en/about-chubb",
            alt: "Chubb",
            fluid: getFluid(data.allFile.edges, "partner/chubb.png"),
        },
        {
            url: "https://www.adyen.com/about",
            alt: "Adyen",
            fluid: getFluid(data.allFile.edges, "partner/adyen.png"),
        },
        {
            url: "https://www.travelfusion.com/corporate/",
            alt: "Travelfusion",
            fluid: getFluid(data.allFile.edges, "partner/travelfusion.png"),
        },
        {
            url: "https://www.salesforce.com/products/marketing-cloud/",
            alt: "Salesforce",
            fluid: getFluid(data.allFile.edges, "partner/salesforce.png"),
        },
        {
            url: "https://www.microsoft.com/en-us/about",
            alt: "Microsoft",
            fluid: getFluid(data.allFile.edges, "partner/microsoft.png"),
        },
    ];

	return (
		<>
			<div id="partners" className="bg-white">
				<div className="container p-4">
					<h1 className="mb-4 text-3xl font-bold">
						<Trans>Partners</Trans>
					</h1>
				</div>
			</div>
			<div id="partnersBody" className="bg-white mb-32">
				<div className="flex flex-wrap">
					{PARTNERS.map((partner, i) => (
						<div key={i} className="w-full sm:w-1/2 lg:w-1/4">
							<div
								className={`sm:hidden h-full py-4 px-8 ${
									i % 2 === 0 ? "bg-brand-green hover:bg-brand-grey1" : ""
								}`}
							>
								<PartnerSection partner={partner} />
							</div>
							<div
								className={`hidden sm:block lg:hidden h-full py-4 px-8 ${
									(Math.floor(i / 2) + i) % 2 === 0 ? "bg-brand-green hover:bg-brand-grey1" : ""
								}`}
							>
								<PartnerSection partner={partner} />
							</div>
							<div
								className={`hidden h-full lg:block py-4 px-8 ${
									(Math.floor(i / 4) + i) % 2 === 0 ? "bg-brand-green hover:bg-brand-grey1" : ""
								}`}
							>
								<PartnerSection partner={partner} />
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Partners;
