export function JobsData(t: Function) {
	return [
		{
			id: "backendDeveloper",
			title: t("Backend Developer"),
			category: "Development",
			enabled: true,
			descriptions: [
				"The Backend Developer will work in a team of developers, architects, and business analysts to develop, test and deploy different types of web applications hosted on-premise or in the cloud. The position is responsible for developing and testing web applications using industry best practices in accordance with development methodology, company coding standards, and SOLID principles. This role will contribute to all development activities including coding, testing, and deployment, excluding design.",
			],
			lists: [
				{
					title: "Responsibilities",
					items: [
						"Develop ASP.NET MVC / WebAPI applications",
						"Use advanced coding techniques to produce fully testable code",
						"Create automated unit test cases and test data",
						"Research and develop cutting edge solutions",
						"Take part in implementing technical solutions and tackling challenges providing innovative and new ideas",
						"Collaborate with other team members (team leader, frontend developers)",
						"Participate in security governance for PCI DSS",
					],
				},
				{
					title: "Requirements",
					items: [
						"At least 3 years of experience developing web applications in .NET, C#",
						"Intermediate experience in RDBMS systems and SQL queries",
						"Experience in one or more of the following: ASP.NET MVC, WebAPI, Web Forms, JavaScript MVC, have basic understanding of web APIs",
						"Strong knowledge: Web Services (SOAP, REST)",
						"Strong understanding of HTTP protocol, HTTP Session, and authentication",
						"Strong grasp of how security principles apply to e-commerce applications",
						"Solid understanding of object oriented design and algorithms",
						"Experience with design patterns",
						"Ability to produce quality code",
						"Good communication skills, fluent English",
					],
				},
				{
					title: "Nice to have",
					items: [
						"NoSQL experience",
						"SQL Server experience in cloud based solutions",
						"Scaling web applications",
						"Designing high traffic web applications",
						"DevOps",
						"Experience in multinational development projects",
					],
				},
			],
		},
		{
			id: "frontendDeveloper",
			title: t("Frontend Developer"),
			category: "Development",
			enabled: true,
			descriptions: [
				"We are looking for full-time frontend developers to join our Budapest office.",
				"The ideal candidate is enthusiastic, flexible, dedicated craftsman who care deeply about best practices. Developer at heart, able to code highly usable interfaces, and have immense respect for the end-user experience and an appreciation of design subtleties that need to be rendered as accurately as possible. We are committed to finding someone who want to continue their career as frontend developer at DGITAL.",
			],
			lists: [
				{
					title: "Our new frontend developer is someone who has",
					items: [
						"Develop ASP.NET MVC / WebAPI applications",
						"Use advanced coding techniques to produce fully testable code",
						"Create automated unit test cases and test data",
						"Research and develop cutting edge solutions",
						"Take part in implementing technical solutions and tackling challenges providing innovative and new ideas",
						"Collaborate with other team members (team leader, frontend developers)",
						"Participate in security governance for PCI DSS",
					],
				},
				{
					title: "Your responsibilities would include",
					items: [
						"Designing websites from layout to function",
						"Creating visually appealing user-friendly sites following industry best practices",
						"Ensuring best user experience",
						"The position may include business trips",
					],
				},
				{
					title: "Bonus points if you have experience in",
					items: [
						"Travel technology",
						"Working with high traffic websites",
						"CSS preprocessors (we use Sass)",
						"Automated browser and unit testing",
						"TypeScript",
						"C#/.NET, Chai, Mocha, ES6, SPA and GIT.",
					],
				},
			],
		},
		// {
		//     id: "devopsEngineer",
		//     title: t("DevOps Engineer"),
		//     category: "Development",
		//     enabled: true,
		//     descriptions: [
		//         "The DevOps Engineer here at DGITAL will work in a team of engineers and architects. The position is about to design and maintain high performance and high availability ecommerce systems in cloud/on premises for our clients."
		//     ],
		//     lists: [
		//         {
		//             title: "Responsibilities",
		//             items: [
		//                 "Maintain our technology stack (.NET 4.6/.NET Core, SQL Server, Elastic, Redis, Cloudflare, Linux/Ubuntu, Windows, Docker/Kubernetes, Azure Functions) in Azure Cloud/On premise",
		//                 "Maintain CI/CD environment for Mobile, FrontEnd and BackEnd apps",
		//                 "Maintain logging/monitoring environment",
		//                 "Apply security best practices",
		//                 "Participate in security governance for PCI DSS",
		//                 "Root cause analysis",
		//                 "Design for high performance and scalability",
		//                 "Infrastructure automation"
		//             ]
		//         },
		//         {
		//             title: "Requirements",
		//             items: [
		//                 "Ability to multi task concurrent initiatives and dynamically reprioritize with ease",
		//                 "Sound programming fundamentals, industry experience in a software engineering role",
		//                 "Working knowledge of shell scripting (Python, Powershell)",
		//                 "Working knowledge of Continuous integration and delivery",
		//                 "Understands the entire technology stack of the company",
		//                 "Strong knowledge of networking concepts, protocols and security (TCP/IP, HTTP, TLS, DNS, VPN)",
		//                 "Strong knowledge of OS principles (Windows, Linux), POSIX",
		//                 "Operational experience with web-scale infrastructure on Public cloud deployments (Azure preferred)",
		//                 "Strong analytical and communication skills",
		//                 "At least BSc degree in Computer Science or relevant field",
		//                 "Proficient understanding of Git"
		//             ]
		//         }
		//     ]
		// },
		// {
		//     id: "businessAnalyst",
		//     title: t("Business Analyst"),
		//     category: "Business",
		//     enabled: true,
		//     descriptions: [
		//         "We are looking for a Business Analyst who is interested in airline ecommerce business, likes to communicate with foreign clients, can proactively manage projects and eager to learn how traveling industry works. The new member will work closely with the developer team and our partners throughout the entire software development lifecycle and will be involved in DGITAL’s pricing and resource strategy as well."
		//     ],
		//     lists: [
		//         {
		//             title: "Responsibilities",
		//             items: [
		//                 "Own and develop relationship with our partners, working with them to optimize and enhance their business",
		//                 "Gather requirements from stakeholders throughout the business and create new product, feature and solution specification in collaboration with development team",
		//                 "Analyse incoming issues, problems, requests and provide feasible solution for our business partners",
		//                 "Manage Agile/Scrum meetings (planning, grooming, review and retrospective) ensuring that the development is completed accurately and deadlines are reached.",
		//                 "Be an owner of the UAT of new features and products before they are deployed into the market",
		//                 "Manage launch activities leading up to and following releases, ensuring that appropriate communications are made to key stakeholders",
		//                 "Document any identified required changes, and communicate them to the relevant stakeholders",
		//                 "Define and analyse metrics that inform the success of the solutions"
		//             ]
		//         },
		//         {
		//             title: "Requirements",
		//             items: [
		//                 "College or University degree in appropriate field",
		//                 "Work experience in relevant field or industry",
		//                 "Active professional English language skills (spoken and written)",
		//                 "Strong problem-solving and communication skills",
		//                 "Demonstrated ability to take ownership and get things done"
		//             ]
		//         },
		//         {
		//             title: "Nice to have",
		//             items: [
		//                 "Basic SQL or NoSQL knowledge",
		//                 "Strong understanding of Agile methodologies",
		//                 "Basic knowledge of HTML and UI principals"
		//             ]
		//         }
		//     ]
		// },
		// {
		//     id: "itInfrastructureSupport",
		//     title: t("IT Infrastructure Support"),
		//     category: "Infra",
		//     enabled: true,
		//     descriptions: [
		//         "The IT Infra Support Engineer here at DGITAL will work in a team of engineers, architects to operate the company’s infrastructure both on-premise and in the cloud. This position is responsible for maintaining the secure environment using industry best practices."
		//     ],
		//     lists: [
		//         {
		//             title: "Responsibilities",
		//             items: [
		//                 "Handling and tracking of incidents (Service Desk)",
		//                 "Rollout web applications",
		//                 "Collaborate with other team members (developers)",
		//                 "Take part in implementing technical solutions and tackling challenges providing innovative and new ideas",
		//                 "Participate in security governance for PCI DSS"
		//             ]
		//         },
		//         {
		//             title: "Requirements",
		//             items: [
		//                 "At least 2 years of experience at a multinational company",
		//                 "Experience with Azure",
		//                 "PowerShell Scripting experience",
		//                 "Experience of Windows 2012 R2",
		//                 "Active Directory",
		//                 "GPO",
		//                 "IIS",
		//                 "Strong problem-solving and troubleshooting skills",
		//                 "Intermediate English"
		//             ]
		//         },
		//         {
		//             title: "Advantages",
		//             items: [
		//                 "Cisco knowledge",
		//                 "ITIL processes",
		//                 "Linux knowledge",
		//                 "Monitoring tools: Zabbix, Nagios",
		//                 "VoIP, 3cx"
		//             ]
		//         }
		//     ]
		// }
	];
}
